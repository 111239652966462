import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useCallFetchv2 } from 'helpers/callFetchv2';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import SoftTypography from 'components/SoftTypography';
import { useForm } from "react-hook-form";

function IndexTable(props) {
    const { t } = useTranslation();
    const callFetch = useCallFetchv2();
    var typingTimer;
    const [loading, setLoading] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);


    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    const purchaseSave = (data, datum) => {
        //console.log(props?.project_id);

        var formData = {}
        formData.project_id = props?.project_id;
        formData.product_id = data?.value;
        formData.product_type = data?.type;

        if (datum === 'bestelldatum') {
            formData.bestelldatum = getValues("bestelldatum[" + data?.value + "]");
        } else if (datum === 'versanddatum') {
            formData.versanddatum = getValues("versanddatum[" + data?.value + "]");
        } else if (datum === 'lieferdatum') {
            formData.lieferdatum = getValues("lieferdatum[" + data?.value + "]");
        } else if (datum === 'delivery_link') {
            formData.delivery_link = getValues("delivery_link[" + data?.value + "]");
        }
        // formData.lieferdatum = getValues("lieferdatum[" +data?.value + "]");
        formData.qty = getValues("qty[" + data?.value + "]");

        console.log(formData);

        callFetch('beschaffungsliste', "POST", formData, setError).then((res) => {
            //setSaving(false);
            if (!res.ok) return;
            //setSubmitSuccess(true);
            setRefresh(refresh + 1);
        });
    }

    const onSubmit = (formData) => {

    };

    const tableHeadings = [
        {
            name: t('Product Nr.'),
            width: '100px',
            selector: row => <SoftTypography variant="caption" style={{ textDecoration: 'underline', color: 'rgb(0, 84, 152)' }}>{row?.value}</SoftTypography>,
            sortable: false,
        },
        {
            name: t('Description'),
            width: '280px',
            selector: row => <>
                <span title={row?.label}>{row?.label}</span>

                <input
                    className={'form-control form-control-sm mt-1 '}
                    type='text'
                    style={{width: '230px'}}
                    placeholder={t('Tracking Link einfügen')}
                    {...register("delivery_link[" + row?.value + "]", {
                        required: true,
                    })}
                    onBlur={(e) => {
                        purchaseSave(row, 'delivery_link');
                    }}
                />
            </>,
            sortable: false,
        },
        {
            name: t('Quantity'),
            width: '180px',
            selector: row => (
                <>
                    <input value={row?.quantity} {...register("qty[" + row?.value + "]", {
                        required: true,
                    })} readOnly className='form-control form-control-sm' style={{ width: '60%', float: 'left' }} />
                    <SoftTypography style={{ position: 'relative', top: '8px', left: '5px' }} variant="caption">Stk./m</SoftTypography>
                </>
            ),
            sortable: false,
        },
        {
            name: t('Supplier'),
            width: '100px',
            selector: row => 'Senec AG',
            sortable: false,
        },
        {
            name: t('Kategorie'),
            width: '90px',
            selector: row => <span style={{ textTransform: 'uppercase' }}>{row?.type}</span>,
            sortable: false,
        },
        {
            name: t('Bestelldatum'),
            //width: '120px',
            selector: row => (
                <>
                    <input
                        className={'form-control form-control-sm mt-1 '}
                        type='date'
                        placeholder="Select a date"
                        {...register("bestelldatum[" + row?.value + "]", {
                            required: true,
                        })}
                    />
                    <div className='my-1'>
                        <a
                            onClick={(e) => {
                                purchaseSave(row, 'bestelldatum');
                            }}
                            className={"btn m-0 btn-sm w-100 " + (getValues("bestelldatum[" + row?.value + "]") ? 'btn-ac' : 'btn-primary')}
                            style={{ textTransform: 'capitalize' }}
                        >{t('Bestelldatum')}</a>
                    </div>
                </>
            ),
            sortable: false,
        },
 
        {
            name: t('vor. Lieferdatum'),
            //width: '120px',
            selector: row => (
                <>
                    <input
                        className={'form-control form-control-sm mt-1 '}
                        type='date'
                        placeholder="Select a date"
                        {...register("lieferdatum[" + row?.value + "]", {
                            required: true,
                        })}
                    />
                    <div className='my-1'>
                        <a
                            onClick={(e) => {
                                purchaseSave(row, 'lieferdatum');
                            }}
                            className={"btn m-0 btn-sm w-100 " + (getValues("lieferdatum[" + row?.value + "]") ? 'btn-ac' : 'btn-primary')}
                            style={{ textTransform: 'capitalize' }}
                        >{t('Lieferdatum')}</a>
                    </div>
                </>
            ),
            sortable: false,
        },
    ];

    useEffect(() => {
        if (!(props?.project_id ? props?.project_id : 0)) return;
        reset({});

        setLoading(true);

        callFetch("product-purchase/details/" + (props?.project_id ? props?.project_id : 0) + "?page=" + pageNumber, "GET", []).then((res) => {
            setTableData(res.data);

            if (res?.beschaffung && res?.beschaffung?.items) {
                for (let [key, value] of Object.entries(res?.beschaffung?.items)) {
                    //console.log(value);
                    //setValue(key, (value == null || value == 'null' ? "" : value));

                    //setValue("qty[" + value?.product_id + "]", (value?.qty == null || value?.qty == 'null' ? "" : value?.qty));
                    setValue("lieferdatum[" + value?.product_id + "]", (value?.lieferdatum == null || value?.lieferdatum == 'null' ? "" : value?.lieferdatum)); 
                    setValue("bestelldatum[" + value?.product_id + "]", (value?.bestelldatum == null || value?.bestelldatum == 'null' ? "" : value?.bestelldatum));
                    setValue("delivery_link[" + value?.product_id + "]", (value?.delivery_link == null || value?.delivery_link == 'null' ? "" : value?.delivery_link));
                }
            }

            setLoading(false);
            setRefresh2(refresh2 + 1);
        });

    }, [pageNumber, refresh, (props?.project_id ? props?.project_id : 0)]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />

                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                                type='button'
                            >
                                &#60;
                            </button>
                        </li>

                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                        type='button'
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}

                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                                type='button'
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return (
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"></form>
            <DataTable
                columns={tableHeadings}
                data={tabledata?.data}
                noDataComponent={loading ? t('Loading...') : t('There are no records to display')}
                className='data-table'
                pagination
                highlightOnHover
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationServer
                paginationTotalRows={tabledata?.total}
                onChangePage={handlePageChange}
                paginationComponent={BootyPagination}
            />
            <form />
        </>
    )
}

export default IndexTable;