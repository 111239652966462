import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch";
import Timeline from "./Timeline";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import SoftButton from "components/SoftButton";
import MiniStatisticsCard from "./Components/EEC/MiniStatisticsCard"; 

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
// Images
import mercedesEQC from "assets/images/mercedes-eqc.png";
import wavesWhite from "assets/images/shapes/waves-white.svg";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import ProductImages from "./Components/EEC/ProductImages";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import breakpoints from "assets/theme/base/breakpoints";
import { fontSize } from "@mui/system";
import TableEstimatedProductionConsumption from "./Components/EEC/TableEstimatedProductionConsumption";
import ThinBarChart from "examples/Charts/BarCharts/ThinBarChart";
import thinBarChartData from "layouts/dashboards/smart-home/data/thinBarChartData";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import defaultLineChartData from "layouts/pages/charts/data/defaultLineChartData";
import VideoComponent from "./Components/EEC/Video";
import EECButton from "./Components/EEC/EECButton";
import EstimatedProduction from "./Components/EEC/EstimatedProduction";
import EstimatedProductionTwo from "./Components/EEC/EstimatedProductionTwo";
import EstimatedProductionThree from "./Components/EEC/EstimatedProductionThreeLineChart";
import { NumericFormat } from 'react-number-format'; 
import ProfitCalculatonCreate from './ProfitCalculation/Create';
import EmailForm from "./EmailForm";
function Wirtschaftlichkeitsberechnung(props) {
    const { t } = useTranslation();
    const [create, setCreate] = useState(false);
    const [resaleAngebot, setResaleAngebot] = useState(false);
    const addNewAngebot = () => {
        //console.log('addNewAngebot')
        setCreate(true);
        setResaleAngebot(true);
        setTimeout(() => {
            document.getElementById('editdstBtn').click();
        }, 1000)
    }

    const [refreshKey, setRefreshKey] = useState(0);

    // Function to trigger refresh
    const handleUpdate = () => {
        setRefreshKey((prevKey) => prevKey + 1); // Increment the key to force refresh
    };

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(1);
    let params = useParams();
    const [data, setData] = useState([]);
    const [profit_data, setProfitData] = useState([]);
    const [annual_output, setAnnualOutput] = useState(0);
    const [feedInTariff, setFeedInTariff] = useState(0);
    const [saving, setSaving] = useState(0);
    const [errorSB, setErrorSB] = useState(false);
    const [selectedQuationId, setSelectedQuationId] = useState('');
    const [quationRefresh, setQuationRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const primaryAngebot = (primary, quation_id) => {
        var formData = {};
        formData.primary = (primary ? '1' : '0');
        formData.id = quation_id;
        formData.project_id = (props?.projectid ? props?.projectid : params.id);

        callFetch('primary-angebot', "POST", formData, setError).then((res) => {

        });
    }

    const editAngebot = (id) => {
        setSelectedQuationId(id);
        setCreate(false);
        setQuationRefresh(quationRefresh + 1);
    }

    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
        return window.innerWidth < breakpoints.values.sm
            ? setTabsOrientation("vertical")
            : setTabsOrientation("horizontal");
        }

        /** 
         The event listener that's calling the handleTabsOrientation function when resizing the window.
        */
        window.addEventListener("resize", handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

 


    useEffect(() => {

            callFetch("economic-efficiency-calculation/" + (props?.projectid ? props?.projectid : params.id), "GET", []).then((res) => {

                var resData = res?.data;
                console.log(res.data);

                if (!resData?.quation) {
                    resData.quation = {};
                }

                setData(resData);
                setProfitData(res?.profit_calc_data);
                // console.log(res?.profit_calc_data?.productItems);
                console.log(res?.profit_calc_data?.moduleWR);
                // let annual_output_calc = ((data?.quation?.anzahl_module * 405) / 1000) * 1000;
                // setAnnualOutput(annual_output_calc);

                // let feedInTariffCalc = (annual_output_calc * (data?.quation?.einspeiseverguetung_preis_percent / 100));
                // setFeedInTariff(feedInTariffCalc);

                // let savingCalc = (annual_output_calc * ((100-data?.quation?.einspeiseverguetung_preis_percent) / 100));
                // setSaving(savingCalc);
                
  
                // console.log(resData);
              
            });

            callFetch("get-module-info"+'/'+params.id, "GET", []).then((res) => { 
                // Log "test" if any of them are null
                if (
                    res.data.einspeiseverguetung_preis == null || res.data.einspeiseverguetung_preis === 0 ||
                    res.data.einspeiseverguetung_preis_percent == null || res.data.einspeiseverguetung_preis_percent === 0 ||
                    res.data.kaufpreisEigenverbrauch == null || res.data.kaufpreisEigenverbrauch === 0 ||
                    res.data.kaufpreisEigenverbrauch_percent == null || res.data.kaufpreisEigenverbrauch_percent === 0
                ) {
                    document.getElementById('profitModal').click();
                }
                
            });
        
    }, []);
 

    const handleSetTabValue = (event, newValue) => {
        //console.log(newValue);
        setTabValue(newValue);
        if(newValue == 1){
            // document.getElementById('wirtschaftlichkeitsberechnung').click();
        }else{
            document.getElementById('distribution').click();
        }
    };


    return (
        <>
        <p data-bs-toggle="modal" data-bs-target="#staticBackdropddd" id="profitModal"></p>
        <div className="row"> 
        <div className={props?.fullwidth != false ? "col-lg-9 mt-lg-0 inner-main" : 'col-12 inner-main'} id="angebot-from-area">
        <SoftBox>
                <Grid container>
                <Grid mt={1} mb={3} item xs={12} sm={8} lg={4}>
                    <AppBar position="static">
                    <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                        <Tab label="Angebot" />
                        <Tab label="Wirtschaftlichkeitsberechnung" /> 
                    </Tabs>
                    </AppBar>
                </Grid>
                </Grid> 
            </SoftBox>
        <SoftBox mb={1} mt={1}>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6} lg={4}>
                    <MiniStatisticsCard
                    bgColor="white"
                    title={{ text: "Module", fontWeight: "medium", color: "#A0AEC0" }}
                    count={data?.quation?.anzahl_module}
                    icon={{ color: 'dark', component: "/assets/img/eec/1.png" }}
                    direction="left"
                    unit="Stück"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <MiniStatisticsCard
                    bgColor="white"
                    title={{ text: "Speicher", fontWeight: "bold", color: "#A0AEC0" }}
                    count={data?.speicher}
                    icon={{ color: 'dark', component: "/assets/img/eec/2.png" }}
                    direction="left"
                    unit="Stück"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <MiniStatisticsCard
                    bgColor="white"
                    title={{ text: "Speicherkapazität", fontWeight: "bold", color: "#A0AEC0" }}
                    count={data?.speicher_watt}
                    icon={{ color: 'dark', component: "/assets/img/eec/3.png" }}
                    direction="left"
                    unit="Kwh"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <MiniStatisticsCard
                    bgColor="white"
                    title={{ text: "Wechselrichter", fontWeight: "bold", color: "#A0AEC0" }}
                    count={data?.wechselrichter}
                    icon={{ color: "dark", component: "/assets/img/eec/4.png" }}
                    direction="left"
                    unit="Stück"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <MiniStatisticsCard
                    bgColor="white"
                    title={{ text: "DC-Nennleistung", fontWeight: "bold", color: "#A0AEC0" }}
                    count={(<NumericFormat
                        value={Number(data?.module_watt)}
                        displayType="text"
                        thousandSeparator={"."}
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={''}
                    />)}
                    icon={{ color: "dark", component: "/assets/img/eec/5.png" }}
                    direction="left"
                    unit="kWp"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <MiniStatisticsCard
                    bgColor="white"
                    title={{ text: "AC-Leistung", fontWeight: "bold", color: "#A0AEC0" }}
                    count="15"
                    icon={{ color: "dark", component: "/assets/img/eec/6.png" }}
                    direction="left"
                    unit="kWe"
                    />
                </Grid>
            </Grid>
        </SoftBox>

        {/* <SoftBox mt={5}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <ProductImages />
                </Grid>
            </Grid>
        </SoftBox> */}
       
        <SoftBox mt={5} className="eec-video-right-text">
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <VideoComponent></VideoComponent>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className="card">
                                <div className="card-body text-center">
                                    <SoftTypography className="text-gradient" style={{textAlign:'center'}}>
                                     {data?.module_watt}<sub style={{color: '#208DFC', fontSize: '1rem'}}>kWp</sub>
                                    </SoftTypography>
                                    <SoftTypography variant="h5" className="font-size-16 custom-text-dark" fontWeight="bold">
                                        Modulleistung
                                    </SoftTypography>
                                    <SoftTypography variant="caption" style={{color: '#8392AB'}} fontSize="0.875rem">
                                        kWp
                                    </SoftTypography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <div className="card">
                                <div className="card-body text-center">
                                    <SoftTypography className="text-gradient" style={{textAlign:'center'}}>
                                     {data?.Modulleistung_von_watt}<sub style={{color: '#208DFC', fontSize: '1rem'}}>Watt</sub>
                                    </SoftTypography>
                                    <SoftTypography variant="h5" className="font-size-16 custom-text-dark" fontWeight="bold">
                                        Modulleistung
                                    </SoftTypography>
                                    <SoftTypography variant="caption" style={{color: '#8392AB'}} fontSize="0.875rem">
                                        Watt
                                    </SoftTypography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <div className="card pt-4 pb-4">
                                <div className="card-body text-center">
                                    <SoftTypography variant="h5" fontSize="1rem" style={{textAlign:'center'}}>
                                            Über die Lebensdauer der Anlage
                                    </SoftTypography>
                                    <div className="d-flex justify-content-between mt-3">
                                        <div className="eec-item">
                                            <SoftTypography variant="h6" fontWeight="bold" fontSize="1rem">
                                            <NumericFormat
                                                value={Number(data?.vermiedene_auto)}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale
                                                suffix={''}
                                            />   <img src="/assets/img/eec/9.png"/> 
                                            </SoftTypography>
                                            <SoftTypography variant="caption" style={{fontWeight: '600'}}>
                                                Vermiedene Auto Km
                                            </SoftTypography>
                                        </div>
                                        <div className="eec-item">
                                            <SoftTypography variant="h6" fontSize="1rem" fontWeight="bold">
                                            <NumericFormat
                                                value={Number(data?.gepflanzte)}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale
                                                suffix={''}
                                            />   <img src="/assets/img/eec/8.png"/>
                                            </SoftTypography>
                                            <SoftTypography variant="caption" style={{fontWeight: '600'}}>
                                                Gepflanzte Bäume
                                            </SoftTypography>
                                        </div>
                                        <div className="eec-item">
                                            <SoftTypography variant="h6" fontSize="1rem" fontWeight="bold">
                                            <NumericFormat
                                                value={Number(data?.langstreckenfluge)}
                                                displayType="text"
                                                thousandSeparator={"."}
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale
                                                suffix={''}
                                            />   <img src="/assets/img/eec/7.png"/>
                                            </SoftTypography>
                                            <SoftTypography variant="caption" style={{fontWeight: '600'}}>
                                                Vermiedene Langstreckenflüge
                                            </SoftTypography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SoftBox>

        <SoftBox mt={5} mb={5}>
            <div className="card p-3 mb-5">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-7"><SoftTypography variant="h5" fontWeight="bold" fontSize="1.25rem">Wirtschaftlichkeitsberechnung für Ihre Anlage</SoftTypography></div>
                        <div className="col-sm-5">
                            <p data-bs-toggle="modal" data-bs-target="#staticBackdropddd" className="data-collection-text text-end">{t('Datenerfassung für die Wirtschaftlichkeitsberechnunung')}</p>
                        </div>
                    </div>
                    
                    <div className="row mt-5 gx-8">
                        <div className="col-sm-5">
                            <SoftTypography variant="h6" fontWeight="normal" fontSize="1rem">Modultyp: <span style={{fontWeight: '600'}}>{profit_data?.moduleWR?.name}</span></SoftTypography> 
                        </div>
                        <div className="col-sm-7">
                            <div className="d-flex">
                                <SoftTypography variant="h6" fontWeight="normal" fontSize="1rem">Anzhl - Module:</SoftTypography>
                                &nbsp;&nbsp;&nbsp;<EECButton text={data?.quation?.anzahl_module} width="100px" padding="0px" bgColor="#8392AB"></EECButton>
                            </div>
                        </div>
                    </div> 
                    
                    <div className="row mt-4 gx-8">
                        <div className="col-sm-5">
                            <div className="d-flex">
                                <SoftTypography variant="h6" fontWeight="normal" fontSize="1rem">Gesamtleistung:</SoftTypography>
                                &nbsp;&nbsp;&nbsp;<EECButton text={data?.module_watt + ' kWh'}   width="100px" padding="0px 0px" bgColor="#8392AB"></EECButton>
                            </div>
                        </div>
                        <div className="col-sm-7">
                            <div className="d-flex">
                                <SoftTypography variant="h6" fontWeight="normal" fontSize="1rem">In Simulation ermittelte kWh/KWp p.a.:</SoftTypography>
                                &nbsp;&nbsp;&nbsp; <EECButton text={data?.quation?.sonnenstunden} width="100px" padding="0px" bgColor="#8392AB"></EECButton>
                            </div>
                        </div>
                    </div> 

                    <div className="row mt-4 gx-8 mb-4">
                        <div className="col-sm-12">
                            <div className="d-flex justify-content-center mt-4">
                                <SoftTypography variant="h6" fontWeight="normal" fontSize="1rem">Ergibt Jahresleistung der Anlage in kWh (nach gangiger Simulationssoftware):&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;</SoftTypography>
                                <EECButton text={(<NumericFormat
                            value={profit_data?.annual_output_calc}
                            displayType="text"
                            // thousandSeparator={"."}
                            decimalSeparator="."
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' kWh'}
                    />)}   width="150px" padding="0px" bgColor="#CB0C9F"></EECButton>
                            </div>
                        </div> 
                    </div>  

                    <br/>
                    <br/>

                    <SoftTypography variant="h5" fontWeight="bold" fontSize="1.25rem">In Simulation ermittelte Einspeiseverqutung/Ersparnis (Eigenverbrauch berücksichtigt):</SoftTypography>
                    <div className="row mt-5 gx-6">
                        <div className="col-sm-6">
                            <div className="mt-3 d-flex justify-content-between">
                                <SoftTypography variant="h6" fontWeight="normal" fontSize="1rem">Über 10 kW bis 40 kW:</SoftTypography>
                                <div>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <EECButton text={(<NumericFormat
                            value={profit_data?.feedInTariffCalc}
                            displayType="text"
                            // thousandSeparator={"."}
                            decimalSeparator="."
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' kWh'}
                    />)}  width="120px" padding="0px" bgColor="#405270"></EECButton> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <EECButton text={data?.quation?.einspeiseverguetung_preis+' €/kWh'} width="120px" padding="0px" bgColor="#8392AB"></EECButton>
                                </div>
                            </div>
                            <div className="mt-3 d-flex justify-content-between">
                                <SoftTypography variant="h6" fontWeight="normal" fontSize="1rem">Ersparnis aus Eigenverbrauch</SoftTypography>
                                <div>
                                   <EECButton text={(<NumericFormat
                            value={profit_data?.savingCalc}
                            displayType="text"
                            // thousandSeparator={"."}
                            decimalSeparator="."
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' kWh'}
                    />)}  width="120px" padding="0px" bgColor="#405270"></EECButton> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <EECButton text={data?.quation?.kaufpreisEigenverbrauch+ ' €/kWh'} width="120px" padding="0px" bgColor="#8392AB"></EECButton>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mt-3 d-flex justify-content-between">
                                <SoftTypography variant="h6" fontWeight="normal" fontSize="1rem">kWh nach EEG:</SoftTypography>
                                <EECButton text={(<NumericFormat
                            value={profit_data?.eeg}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' €'}
                    />)} width="100px" padding="0px" bgColor="#6AA325"></EECButton>
                            </div>
                            <div className="mt-3 d-flex justify-content-between">
                                <SoftTypography variant="h6" fontWeight="normal" fontSize="1rem">kWh / Strompreis inkl. Grundpreis:</SoftTypography>
                                <EECButton text={(<NumericFormat
                            value={profit_data?.grundpries}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' €'}
                    />)}  width="100px" padding="0px" bgColor="#6AA325"></EECButton>
                            </div>
                        </div>
                    </div> 

                    <br/>
                    <br/>

                    <div className="row gx-5 mt-5">
                        <div className="col-sm-7 text-end">
                            <SoftTypography variant="h6" fontSize="1rem" fontWeight="medium">Einspeisevergütung + Ersparnis jährlich gesamt</SoftTypography>
                        </div>
                        <div className="col-sm-5">
                            <EECButton text={(<NumericFormat
                            value={profit_data?.feed_in_tariff_annual_saving_total}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' €'}
                    />)} width="110px" padding="0px" bgColor="#405270"></EECButton>
                        </div>
                    </div>

                    <div className="row gx-5 mt-3">
                        <div className="col-sm-7 text-end">
                            <SoftTypography variant="h6" fontSize="1rem" fontWeight="medium">Einspeisevergütung + Ersparnis monatlich gesamt</SoftTypography>
                        </div>
                        <div className="col-sm-5">
                            <EECButton text={(<NumericFormat
                            value={profit_data?.feed_in_tariff_annual_saving_total_monthly}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' €'}
                    />)} width="110px" padding="0px" bgColor="#405270"></EECButton>
                        </div>
                    </div>

                    <br/>
                    <br/>

                    <div className="row gx-5 mt-5">
                        <div className="col-sm-7 text-end">
                            <SoftTypography variant="h6" fontSize="1.25rem" fontWeight="bold">Komplettpreis für anschlussfertige Photovoltaik-Anlage:</SoftTypography>
                        </div>
                        <div className="col-sm-5">
                            <EECButton text={(<NumericFormat
                            value={Number(profit_data?.subTotal)}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' €'}
                    />)} width="110px" padding="0px" bgColor="#CB0C9F"></EECButton>
                        </div>
                    </div>

                    <div className="row gx-5 mt-3">
                        <div className="col-sm-7 text-end">
                            <SoftTypography variant="h6" fontSize="1rem" fontWeight="normal">Mehrwertsteuer, {profit_data?.ust_position}% (kann vom Finanzamt erstattet werden)</SoftTypography>
                        </div>
                        <div className="col-sm-5">
                            <EECButton text={(<NumericFormat
                            value={Number(profit_data?.ust_position_amount)}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' €'}
                    />)} width="110px" padding="0px" bgColor="#CB0C9F"></EECButton>
                        </div>
                    </div>
                   
                    <div className="row gx-5 mt-3">
                        <div className="col-sm-7 text-end">
                            <SoftTypography variant="h6" fontSize="1.25rem" fontWeight="bold">Gesamtsumme Brutto</SoftTypography>
                        </div>
                        <div className="col-sm-5">
                            <EECButton text={(<NumericFormat
                            value={Number(profit_data?.subTotal)}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' €'}
                    />)} width="110px" padding="0px" bgColor="#CB0C9F"></EECButton>
                        </div>
                    </div>

                    <br/>
                    <br/> 

                    <div className="row gx-5 mt-3">
                        <div className="col-sm-7 text-end">
                            <SoftTypography variant="h6" fontSize="1rem" fontWeight="normal">Einspeisertrag (netto) pro Jahr lt. Simulation:</SoftTypography>
                        </div>
                        <div className="col-sm-5">
                            <EECButton text={(<NumericFormat
                            value={profit_data?.feed_in_tariff_annual_saving_total}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' €'}
                    />)} width="110px" padding="0px" bgColor="#405270"></EECButton>
                        </div>
                    </div>
                   
                    <div className="row gx-5 mt-3">
                        <div className="col-sm-7 text-end">
                            <SoftTypography variant="h6" fontSize="1rem" fontWeight="normal">kWh Netzbezug pro Jahr in kWh</SoftTypography>
                        </div>
                        <div className="col-sm-5">
                            <EECButton text={(<NumericFormat
                            value={profit_data?.grid_consumption_per_year}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' €'}
                    />)}  width="110px" padding="0px" bgColor="#405270"></EECButton>
                        </div>
                    </div> 
                    
                    <div className="row gx-5 mt-3">
                        <div className="col-sm-7 text-end">
                            <SoftTypography variant="h6" fontSize="1.25rem" fontWeight="bold">Anlagen-Ertrag p.a. 1</SoftTypography>
                        </div>
                        <div className="col-sm-5">
                            <EECButton text={(<NumericFormat
                            value={profit_data?.investment_yield}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' €'}
                    />)} width="110px" padding="0px" bgColor="#405270"></EECButton>
                        </div>
                    </div>


                    <br/>
                    <br/>

                    <div className="row gx-5 mt-5">
                        <div className="col-sm-7 text-end">
                            <SoftTypography variant="h6" fontSize="1.25rem" fontWeight="bold">Ertrag im Verhältnis zur Nettoinvestition:</SoftTypography>
                        </div>
                        <div className="col-sm-5">
                            
                        </div>
                    </div>

                    <div className="row gx-5 mt-3">
                        <div className="col-sm-7 text-end">
                            <SoftTypography variant="h6" fontSize="1rem" fontWeight="normal">Das entspricht einem Ertragsverhältnis zur Nettoinvestition von</SoftTypography>
                        </div>
                        <div className="col-sm-5">
                            <EECButton text={(<NumericFormat
                            value={profit_data?.roi}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' %'}
                    />)} width="110px" padding="0px" bgColor="#405270"></EECButton>
                        </div>
                    </div>
                   
                    <div className="row gx-5 mt-3">
                        <div className="col-sm-7 text-end">
                            <SoftTypography variant="h6" fontSize="1rem" fontWeight="normal">Armotisierung der Anlage in Jahren</SoftTypography>
                        </div>
                        <div className="col-sm-5">
                            <EECButton text={(<NumericFormat
                            value={profit_data?.plant_in_years}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' Jahre'}
                    />)} width="110px" padding="0px" bgColor="#405270"></EECButton>
                        </div>
                    </div>
                    
                    <div className="row gx-5 mt-3">
                        <div className="col-sm-7 text-end">
                            <SoftTypography variant="h6" fontSize="1rem" fontWeight="normal">Geförderter Zeitraum (zzgl. Der Monate des Jahres der Inbetriebnahme)</SoftTypography>
                        </div>
                        <div className="col-sm-5">
                            <EECButton text="20 Jahre" width="110px" padding="0px" bgColor="#405270"></EECButton>
                        </div>
                    </div>
                    

                    <br/>
                    <br/> 
                    
                    
                    <div className="row gx-5 mt-5">
                        <div className="col-sm-7 text-end">
                            <SoftTypography variant="caption" fontSize="1rem" fontWeight="bold">Einspeisevergütung und Ersparnis (3% Steigerung) <br/>
                            über 20 Jahre Förderzeitraum</SoftTypography>
                        </div>
                        <div className="col-sm-5">
                            <EECButton text={(<NumericFormat
                            value={profit_data?.feed_in_tariff_and_savings}
                            displayType="text"
                            thousandSeparator={"."}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={' €'}
                    />)} width="110px" padding="0px 0px" bgColor="#12B76A"></EECButton>
                        </div>
                    </div>

                </div>
            </div>
        </SoftBox>
        
        <SoftBox mt={5}>
            <div className="card p-3">
                <div className="card-body">
                    <SoftTypography variant="h4">Geschätzte Produktion und Verbrauch</SoftTypography>
                    <div className="row mt-4">
                        <div className="col-sm-4">
                            <div className="card" style={{boxShadow: 'none', border: '1px dashed #ddd'}}>
                                <div className="card-body text-center">
                                    <SoftTypography variant="caption" className="consumption-text" style={{color: '#208DFC', fontSize: '20px', fontWeight: 'bold'}}>Jährläche Energieproduktion</SoftTypography> 
                                    <br/>     
                                    <br/>
                                    <SoftTypography variant="caption" fontWeight="bold" style={{fontSize: '24px', fontWeight: 'bold'}}><NumericFormat value={Number(data?.roof_direction_module_efficiency)} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} fixedDecimalScale suffix={''}/> kWh</SoftTypography>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                        <div className="card" style={{boxShadow: 'none', border: '1px dashed #ddd'}}>
                                <div className="card-body text-center">
                                    <SoftTypography variant="caption" className="consumption-text" style={{color: '#208DFC', fontSize: '20px', fontWeight: 'bold'}}>Jährlicher Stromverbrauch</SoftTypography> 
                                    <br/>
                                    <br/>
                                    <SoftTypography variant="caption" fontWeight="bold" style={{fontSize: '24px', fontWeight: 'bold'}}><NumericFormat value={Number(data?.quation?.eigenverbrauch)} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} fixedDecimalScale suffix={''}/> kWh</SoftTypography>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                        <div className="card" style={{boxShadow: 'none', border: '1px dashed #ddd'}}>
                                <div className="card-body text-center">
                                    <SoftTypography variant="caption" className="consumption-text" style={{color: '#208DFC', fontSize: '20px', fontWeight: 'bold'}}> Jährlicher Eigenverbauch</SoftTypography> 
                                    <br/>
                                    <br/>
                                    <SoftTypography variant="caption" fontWeight="bold" style={{fontSize: '24px', fontWeight: 'bold'}}><NumericFormat value={Number(data?.quation?.own_consumption)} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} fixedDecimalScale suffix={''}/> kWh</SoftTypography>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-sm-12">
                            <TableEstimatedProductionConsumption onUpdate={handleUpdate}></TableEstimatedProductionConsumption>
                        </div>
                    </div>
                    
                    <div className="row mt-5">
                        <div className="col-sm-12">
                            <EstimatedProduction refreshKey={refreshKey}></EstimatedProduction>
                        </div>
                    </div> 

                </div>
            </div>
        </SoftBox>

        <SoftBox mt={5}>
            <div className="card p-3">
                <div className="card-body">
                    <SoftTypography variant="h4">Geschätzte Produktion und Verbrauch</SoftTypography>
                    <div className="row mt-4">
                        <div className="col-sm-4">
                            <div className="card" style={{boxShadow: 'none', border: '1px dashed #ddd'}}>
                                <div className="card-body text-center">
                                    <SoftTypography variant="caption" className="consumption-text" style={{color: '#208DFC', fontSize: '20px', fontWeight: 'bold'}}>Jährliche      Eigenverbrauch </SoftTypography> 
                                    <br/>
                                    <br/>
                                    <SoftTypography variant="caption" fontWeight="bold" style={{fontSize: '24px', fontWeight: 'bold'}}><NumericFormat value={Number(data?.quation?.own_consumption)} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} fixedDecimalScale suffix={''}/> kWh</SoftTypography>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                        <div className="card" style={{boxShadow: 'none', border: '1px dashed #ddd'}}>
                                <div className="card-body text-center">
                                    <SoftTypography variant="caption" className="consumption-text" style={{color: '#208DFC', fontSize: '20px', fontWeight: 'bold'}}> Notstrom-Zeit
  </SoftTypography> 
                                    <br/>
                                    <br/>
                                    <SoftTypography variant="caption" fontWeight="bold" style={{fontSize: '24px', fontWeight: 'bold'}}>{data?.speicher_watt} kWh</SoftTypography>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                        <div className="card" style={{boxShadow: 'none', border: '1px dashed #ddd'}}>
                                <div className="card-body text-center">
                                    <SoftTypography variant="caption" className="consumption-text" style={{color: '#208DFC', fontSize: '20px', fontWeight: 'bold'}}>  Notstrom-Kapazität
  </SoftTypography> 
                                    <br/>
                                    <br/>
                                    <SoftTypography variant="caption" fontWeight="bold" style={{fontSize: '24px', fontWeight: 'bold'}}>{data?.speicher_watt_clc} kWh</SoftTypography>
                                </div>
                            </div>
                        </div>
                    </div>

                   
                    
                    <div className="row mt-5">
                        <div className="col-sm-12">
                           <div className="card">
                                <div className="card-body">
                                    <EstimatedProductionTwo></EstimatedProductionTwo>
                                </div>
                           </div>
                        </div>
                    </div> 
                   
                    <div className="row mt-5">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <EstimatedProductionThree></EstimatedProductionThree>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </SoftBox>


        {/* <SoftBox mt={5}>
            <div className="card p-3">
                <div className="card-body">
                    <SoftTypography variant="h4">Einspeisungen und Amortisation</SoftTypography>
                    <div className="row mt-4">
                        <div className="col-sm-4">
                            <div className="card" style={{boxShadow: 'none', border: '1px dashed #ddd'}}>
                                <div className="card-body text-center">
                                    <SoftTypography variant="caption" className="consumption-text" style={{color: '#208DFC', fontSize: '20px', fontWeight: 'bold'}}>20 Jahre Einnahmen</SoftTypography> 
                                    <br/>
                                    <br/>
                                    <SoftTypography variant="caption" fontWeight="bold" style={{fontSize: '24px', fontWeight: 'bold'}}>8.007 kWh</SoftTypography>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                        <div className="card" style={{boxShadow: 'none', border: '1px dashed #ddd'}}>
                                <div className="card-body text-center">
                                    <SoftTypography variant="caption" className="consumption-text" style={{color: '#208DFC', fontSize: '20px', fontWeight: 'bold'}}>  Amortisation des Solarsystems
  </SoftTypography> 
                                    <br/>
                                    <br/>
                                    <SoftTypography variant="caption" fontWeight="bold" style={{fontSize: '24px', fontWeight: 'bold'}}>15 Jahre, 11 Monate</SoftTypography>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                        <div className="card" style={{boxShadow: 'none', border: '1px dashed #ddd'}}>
                                <div className="card-body text-center">
                                    <SoftTypography variant="caption" className="consumption-text" style={{color: '#208DFC', fontSize: '20px', fontWeight: 'bold'}}>  Deckungsrate meiner Rechnung durch PV
  </SoftTypography> 
                                    <br/>
                                    <br/>
                                    <SoftTypography variant="caption" fontWeight="bold" style={{fontSize: '24px', fontWeight: 'bold'}}>100
                                    %</SoftTypography>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>
                    <br/>
                    <div className="mt-5"></div>
                    <SoftTypography variant="h5" fontWeight="bold" fontSize="1.5rem">In Simulation ermittelte Einspeiseverqutung/Ersparnis (Eigenverbrauch berücksichtigt):</SoftTypography>
                    <div className="row mt-5 gx-8">
                        <div className="col-sm-6">
                            <div className="mt-3 d-flex justify-content-between">
                                <SoftTypography variant="h6" fontWeight="bold" fontSize="1.25rem">Über 10 kW bis 40 kW:</SoftTypography>
                                <div>
                                    <EECButton text="250 kWh" width="120px" padding="0px" bgColor="#208DFC"></EECButton> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <EECButton text="250 kWh" width="120px" padding="0px" bgColor="#718096"></EECButton>
                                </div>
                            </div>
                            <div className="mt-3 d-flex justify-content-between">
                                <SoftTypography variant="h6" fontWeight="bold" fontSize="1.25rem">Ersparnis aus Eigenverbrauch</SoftTypography>
                                <div>
                                    <EECButton text="14.000 €" width="120px" padding="0px" bgColor="#208DFC"></EECButton> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <EECButton text="14.000 €" width="120px" padding="0px" bgColor="#718096"></EECButton>
                                </div>
                            </div>
                            <div className="mt-3 d-flex justify-content-between">
                                <SoftTypography variant="h6" fontWeight="bold" fontSize="1.25rem">Ersparnis aus ihrem Cloudsystem</SoftTypography>
                                <div>
                                    <EECButton text="14.000 €" width="120px" padding="0px" bgColor="#208DFC"></EECButton> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <EECButton text="14.000 €" width="120px" padding="0px" bgColor="#718096"></EECButton>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mt-3 d-flex justify-content-between">
                                <SoftTypography variant="h6" fontWeight="bold" fontSize="1.25rem">kWh nach EEG:</SoftTypography>
                                <EECButton text="15,75 €" width="100px" padding="0px" bgColor="#208DFC"></EECButton>
                            </div>
                            <div className="mt-3 d-flex justify-content-between">
                                <SoftTypography variant="h6" fontWeight="bold" fontSize="1.25rem">kWh / Strompreis inkl. Grundpreis:</SoftTypography>
                                <EECButton text="4.000,11 €" width="100px" padding="0px" bgColor="#208DFC"></EECButton>
                            </div>
                            <div className="mt-3 d-flex justify-content-between">
                                <SoftTypography variant="h6" fontWeight="bold" fontSize="1.25rem">kWh / Strompreis inkl. Grundpreis:</SoftTypography>
                                <EECButton text="6.000,33 €" width="100px" padding="0px" bgColor="#208DFC"></EECButton>
                            </div>
                        </div>
                    </div> 

                    <div className="row mt-5">
                        <div className="col-sm-12">
                            <TableEstimatedProductionConsumption></TableEstimatedProductionConsumption>
                        </div>
                    </div>
                    
                    <div className="row mt-5">
                        <div className="col-sm-12">
                            <ThinBarChart title="Consumption per day" chart={thinBarChartData} />
                        </div>
                    </div> 
                </div>
            </div>
        </SoftBox> */}

         </div> 

        {props?.sidebar != false ? (
                    <>
                        <div className="col-lg-3 inner-main">
                            <div className="row">
                                <div className="col-sm-12">
                                    {data?.quations?.id ? <button className="btn btn-primary" onClick={() => { document.getElementById('digitalSignQuation').click() }} style={{ width: '100%', backgroundColor: '#CB0C9F', borderColor: '#CB0C9F' }}>{t('Unterschreiben')}</button> : <button className="btn btn-secondary" onClick={() => { setErrorSB(true) }} style={{ width: '100%' }}>{t('Unterschreiben')}</button>}

                                    {JSON.parse(Cookies.get('permissions')).indexOf("Reseller") !== -1 ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => {
                                                addNewAngebot();
                                            }}
                                            style={{ width: '100%', backgroundColor: '#72AC27', borderColor: '#72AC27' }}
                                        >{t('Angebot Hinzufügen')}
                                        </button>
                                    ) : <></>}
                                </div>
                                <div className="col-12">
                                    {data?.allquations ? data?.allquations.map((quation, i) => (
                                        <div
                                            className={"card mb-3 "}
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: (quation?.id == data?.quations?.id ? ' #00549740' : '')
                                            }}
                                        >
                                            <div className="card-body p-3">
                                                <div className="d-flex">
                                                    <div>
                                                        <div className="">
                                                            {/*<i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />*/}
                                                            <div

                                                            >
                                                                <input
                                                                    name="primary_quation"
                                                                    type="radio"
                                                                    className="mt-3"
                                                                    defaultChecked={quation?.primary == '1' ? true : false}
                                                                    onChange={(e) => {

                                                                        var updatedata = data;
                                                                        for (let [key, value] of Object.entries(updatedata.allquations)) {
                                                                            //console.log(key)
                                                                            if (value?.id == quation?.id) {
                                                                                updatedata.allquations[key].primary = '1';
                                                                            } else {
                                                                                updatedata.allquations[key].primary = '0';
                                                                            }
                                                                        }

                                                                        setData(updatedata);
                                                                        primaryAngebot(e.target.checked, e.target.value);
                                                                    }}
                                                                    value={quation?.id}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ms-3"
                                                        onClick={() => {
                                                            editAngebot(quation?.id);
                                                        }}
                                                    >
                                                        <div className="numbers">
                                                            <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Quotation Nr.')}</p>
                                                            <h5 className="font-weight-bolder mb-0">
                                                                {quation?.id} {' - ' + quation?.user?.name}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )) : ''}
                                </div>

                                {/*<div className="col-12">
                                    <div className="card mb-3">
                                        <div className="card-body p-3">
                                            <div className="d-flex">
                                                <div>
                                                    <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                        <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                                    </div>
                                                </div>
                                                <div className="ms-3">
                                                    <div className="numbers">
                                                        <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Creator')}</p>
                                                        <h5 className="font-weight-bolder mb-0">
                                                            {data?.quations?.user?.name ? data?.quations?.user?.name : '-'}
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>*/}
                            </div>

                            <div className="card mb-3">
                                <div className="card-header pb-0">
                                    <h6>{t('Email')}</h6>
                                </div>
                                <div className="card-body">
                                    <EmailForm projectid={props?.projectid ? props?.projectid : params.id} id={data?.quations?.id} type={'angebot'} pdf={true} netto={data?.quations?.gesami_netto} />
                                </div>
                            </div>
                            <Timeline refresh={refresh2} />
                        </div>
                    </>
                ) : ''} 
            </div> 
            <ProfitCalculatonCreate></ProfitCalculatonCreate>
        </>
      );
}

export default Wirtschaftlichkeitsberechnung
